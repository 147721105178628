import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import $ from 'jquery'

function Floor() {
    const { t } = useTranslation();
    $(window).ready(function() {
        const heightHeader = $('#header').height();
        $('.wrapper').css({'padding-top': heightHeader+'px'})
    });
    return (
        <Fragment>
            <Container>
                <section className='floor pb-0' id='lantai_6_10_soho' >
                    <div className='section-heading my-3 my-lg-5 my-md-4'>
                        <div dangerouslySetInnerHTML={{ __html: t('floorplan.a1')}}/>
                    </div>
                    <Row className="align-items-center justify-content-between">
                        <Col lg='5' md='5' xs='6'>
                            <picture>
                                <LazyLoadImage alt='Lantai 6 – 10 SOHO' srcSet="/image/floorplan/floor-soho-1.png" width='451' height='392' className='img-fluid' />
                            </picture>
                        </Col>
                        <Col lg='5' md='5' xs='6'>
                            <picture>
                                <LazyLoadImage alt='Lantai 6 – 10 SOHO' srcSet="/image/floorplan/floor-soho-2.png" width='451' height='392' className='img-fluid' />
                            </picture>
                        </Col>
                    </Row>
                </section>
                <section className='floor pb-0' id='lantai5_11_16'>
                    <div className='section-heading my-3 my-lg-5 my-md-4'>
                        <div dangerouslySetInnerHTML={{ __html: t('floorplan.a2')}}/>
                    </div>
                    <Row className="align-items-center justify-content-between">
                        <Col lg='5' md='5' xs='6'>
                            <picture>
                                <LazyLoadImage alt='Lantai 5, 11 - 16' srcSet="/image/floorplan/typicalfloor11th16th.png" width='451' height='392' className='img-fluid' />
                            </picture>
                        </Col>
                        <Col lg='5' md='5' xs='6'>
                            <picture>
                                <LazyLoadImage alt='Lantai 5, 11 - 16' srcSet="/image/floorplan/typicalfloor05thfloor.png" width='451' height='392' className='img-fluid' />
                            </picture>
                        </Col>
                    </Row>
                </section>
                <section className='floor pb-0' id='lantai_18_penthouse'>
                    <div className='section-heading my-3 my-lg-5 my-md-4'>
                        <div dangerouslySetInnerHTML={{ __html: t('floorplan.a3')}}/>
                    </div>
                    <Row className="align-items-center justify-content-center">
                        <Col lg='5' md='5' xs='6'>
                            <picture>
                                <LazyLoadImage alt='Lantai 18 - Penthouse' srcSet="/image/floorplan/typicalfloor18thpenthouse.png" width='451' height='392' className='img-fluid' />
                            </picture>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Fragment>
    );
}

export default Floor;