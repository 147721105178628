import React, { Fragment, useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

function Hero() {
    const [banner, listBanner] = useState([]);
    const [loading, setLoading] = useState();
    useEffect(() => {
        setLoading(true);
        const url = process.env.REACT_APP_API + `banner`
        axios.get(url)
        .then(res => {
            listBanner(res.data)
            setLoading(false);
        })
        .catch(error => console.log(error))
    },[]);
    const options = {
        loop: false,
        dots: true,
        nav: false,
        autoplay: true,
        smartSpeed: 1250,
        items: 1
    }
    if (loading) {
        return(
            <div className="hero_loading">
                <Skeleton/>
            </div>
        );
    }
    return (
        <Fragment>
            <section className="hero">
                {
                    banner.length && (
                        <OwlCarousel className="owl-theme" {...options}>
                            {
                                banner.map((e, i) => {
                                    if(e.page === 'our-space'){
                                        return(
                                            <div className="item" style={{backgroundImage: `url(${e.link_image})`}} key={i}/>
                                        );
                                    }
                                })
                            }
                        </OwlCarousel>
                    )
                }
            </section>
        </Fragment>
    );
}

export default Hero;