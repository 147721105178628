import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactWhatsapp from 'react-whatsapp'
import $ from 'jquery'

function Content() {
    const { t } = useTranslation();

    $(window).ready(function() {
        const heightHeader = $('#header').height();
        $('.wrapper').css({'padding-top': heightHeader+'px'})
        $('.wording').css({
            "padding-top": heightHeader+'px'
        });
    });
    return (
        <Fragment>
            <div className='benefit'>
                <section id='whats_special' className='view wording pb-0'>
                    <Container>
                        <div className='section-heading my-3 my-lg-5 my-md-4 text-center'>
                            <h2>{t('home.title_view')}</h2>
                        </div>
                        <div className='section-body'>
                            <Row>
                                <Col lg='4' md='6' className='p-2'>
                                    <div className='box'>
                                        <div className='overlay'></div>
                                        <picture>
                                            <img srcSet="/image/home/view/view-1.jpg" alt="Ballroom" className='img-fluid' width='400' height='400'/>
                                        </picture>
                                        <div className='box-body'>
                                            <span>Ballroom</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' md='6' className='p-2'>
                                    <div className='box'>
                                        <div className='overlay'></div>
                                        <picture>
                                            <img srcSet="/image/home/view/view-2.jpg" alt="Office Unit With Balcony" className='img-fluid' width='400' height='400'/>
                                        </picture>
                                        <div className='box-body'>
                                            <span>Office Unit With Balcony</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' md='6' className='p-2'>
                                    <div className='box'>
                                        <div className='overlay'></div>
                                        <picture>
                                            <img srcSet="/image/home/view/view-3.jpg" alt="Strategic Location" className='img-fluid' width='400' height='400'/>
                                        </picture>
                                        <div className='box-body'>
                                            <span>Strategic Location</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' md='6' className='p-2'>
                                    <div className='box'>
                                        <div className='overlay'></div>
                                        <picture>
                                            <img srcSet="/image/home/view/view-4.jpg" alt="No Overtime Charge" className='img-fluid' width='400' height='400'/>
                                        </picture>
                                        <div className='box-body'>
                                            <span>No Overtime Charge</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' md='6' className='p-2'>
                                    <div className='box'>
                                        <div className='overlay'></div>
                                        <picture>
                                            <img srcSet="/image/home/view/view-5.png" alt="Easy Toll Access" className='img-fluid' width='400' height='400'/>
                                        </picture>
                                        <div className='box-body'>
                                            <span>Easy Toll Access</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' md='6' className='p-2'>
                                    <div className='box'>
                                        <div className='overlay'></div>
                                        <picture>
                                            <img srcSet="/image/home/view/view-6.jpg" alt="3 Basement Parking & Backyard Parking" className='img-fluid' width='400' height='400'/>
                                        </picture>
                                        <div className='box-body'>
                                            <span>3 Basement Parking & Backyard Parking</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section id='building_facility' className='wording pb-0'>
                    <div className='section-heading text-center my-3 my-lg-5 my-md-4'>
                        <h2>{t('benefit.a1')}</h2>
                    </div>
                    <Container>
                        <div className='section-body'>
                            <Row>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-1.png" alt="Lobby List" className='img-fluid' width='364' height='511' />
                                    </div>
                                </Col>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-2.png" alt="Access Card System" className='img-fluid' width='364' height='511' />
                                    </div>
                                </Col>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-3.png" alt="Digital Information" className='img-fluid' width='364' height='511' />
                                    </div>
                                </Col>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-4.png" alt="Parking Area" className='img-fluid' width='364' height='337' />
                                    </div>
                                </Col>
                                <Col lg='8' md='8' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-5.png" alt="Cafe & Bakery" className='img-fluid' width='744' height='336' />
                                    </div>
                                </Col>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-6.png" alt="Minimarket" className='img-fluid' width='364' height='522' />
                                    </div>
                                </Col>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-7.png" alt="Bank & ATM Mandiri" className='img-fluid' width='364' height='522' />
                                    </div>
                                </Col>
                                <Col lg='4' md='4' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-8.png" alt="ATM BNI" className='img-fluid' width='364' height='522' />
                                    </div>
                                </Col>
                                <Col lg='12' className='p-2'>
                                    <div className='box'>
                                        <img src="/image/benefit/benefit-9.png" alt="A Luxury lobby" className='img-fluid' width='1124' height='522' />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section id='prime_locations' className='wording'>
                    <div className='section-heading text-center my-3 my-lg-5 my-md-4'>
                        <h2>{t('benefit.a2')}</h2>
                    </div>
                    <Container fluid className='px-lg-0'>
                        <div className='item-full'>
                            <img src="/image/benefit/location.jpg" alt="" className='img-fluid item_img' width='1124' height='464' />
                        </div>
                    </Container>
                    <div className='section-heading text-center my-3 my-lg-5 my-md-4 w-50 mx-auto'>
                        <h2>{t('benefit.a3')}</h2>
                    </div>
                    <Container>
                        <div className='section-body w-50 text-center mx-auto'>
                            <p>{t('benefit.a4')}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-5'>
                            <ReactWhatsapp number="+6281385602131" message="Request e-brocure" className="btn-consult">{t('benefit.a5')}</ReactWhatsapp>
                        </div>
                    </Container>
                </section>
            </div>
        </Fragment>
    );
}

export default Content;