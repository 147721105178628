import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Sosmed from '../partials/Sosmed'

// content
import Hero from '../content/home/Hero'
import Testimoni from '../content/home/Testimoni'

function Home() {
    return (
        <Fragment>
            <Helmet>
                <link rel="canonical" href="https://grandslipi.com/" />
                <link rel='shortlink' href="https://grandslipi.com/" />
                {/* Primary Meta Tags */}
                <title>Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat</title>
                <meta name="title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta name="description" content="Grand Slipi Tower adalah sebuah gedung perkantoran stategis yang terletak di Jl. S. Parman Kav. 22-24, Palmerah, Slipi, Jakarta Barat"/>
                <meta name="keywords" content="Grand slipi tower, sewa kantor jakarta, sewa gedung, sewa ruang kantor, sewa kantor di slipi, kantor strategis, jual beli ruang kantor, Gst"/>

                <meta itemprop="title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta itemprop="description" content="Grand Slipi Tower adalah sebuah gedung perkantoran stategis yang terletak di Jl. S. Parman Kav. 22-24, Palmerah, Slipi, Jakarta Barat"/>
                <meta itemprop="image" content="/fav-icon-gst.png"/>

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://grandslipi.com/"/>
                <meta property="og:title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta property="og:description" content="Grand Slipi Tower adalah sebuah gedung perkantoran stategis yang terletak di Jl. S. Parman Kav. 22-24, Palmerah, Slipi, Jakarta Barat"/>
                <meta property="og:image" content="/fav-icon-gst.png"/>

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://grandslipi.com/"/>
                <meta property="twitter:title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta property="twitter:description" content="Grand Slipi Tower adalah sebuah gedung perkantoran stategis yang terletak di Jl. S. Parman Kav. 22-24, Palmerah, Slipi, Jakarta Barat"/>
                <meta property="twitter:image" content="/fav-icon-gst.png"/>
                
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="googlebot-news" content="index, follow"/>
            </Helmet>
            <Sosmed/>
            <h1 className="firstHeading MSI_show_element">Grand Slipi Tower Apartment in Slipi</h1>
            <Hero/>
			<Testimoni/>
        </Fragment>
    );
}

export default Home;