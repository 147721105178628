import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import { useTranslation } from 'react-i18next'
import ReactWhatsapp from 'react-whatsapp'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

function List() {
    const { t } = useTranslation();
    const options = {
        loop: false,
        dots: false,
        nav: true,
        autoplay: false,
        smartSpeed: 1250,
        items: 1,
        navText : ['<i class="fas fa-angle-left"></i>','<i class="fas fa-angle-right"></i>'],
        navElement: 'div'
    }
    const [show, setShow] = useState(false);

    const [detail, setDetail] = useState([]);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        const url = process.env.REACT_APP_API + `ourSpaces/` + e
        axios.get(url)
        .then(res => {
            setDetail(res.data)
        }) 
        setShow(true)
    }

    const [space, listSpace] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const url = process.env.REACT_APP_API + `ourSpaces`
        axios.get(url)
        .then(res => {
            listSpace(res.data)
            setLoading(false);
        })
        .catch(error => console.log(error))
        loadDetailModal()
    },[]);

    const loadDetailModal = () => {
    }
    return (
        <Fragment>
            <section className='list_our-space'>
                <Container>
                    <Row>
                        {
                            loading ?
                            [0,1,2,3,4,5,6,7,8].map((i) => {
                                return(
                                    <Col lg="4" md='6' className="text-center pb-5" key={i}>
                                        <div className='section-body'>
                                            <div className='list-space'>
                                                <Skeleton height={416}/>
                                            </div>
                                        </div>
                                        <div className='section-heading mt-3'>
                                            <h2><Skeleton/></h2>
                                            <p><Skeleton/></p>
                                        </div>
                                    </Col>
                                )
                            })
                            :
                            space.map((content) => {
                                return(
                                    <Col lg="4" md='6' className="text-center pb-5" key={content.id}>
                                        <div className='section-body'>
                                            <div className='list-space'>
                                                <OwlCarousel className="owl-theme" {...options}>
                                                    {
                                                        content.images.map((img, i) => {
                                                            return(
                                                                <div className="item" key={i}>
                                                                    <div className='overlay'></div>
                                                                    <img src={img} alt="" className='img-fluid' width='356' height='356'  onClick={() => handleShow(content.id)} />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </OwlCarousel>
                                                <ul>
                                                    <li>
                                                        <p>{t('ourspace.space')}</p>
                                                        <p>{content.space} m²</p>
                                                    </li>
                                                    <li>
                                                        <p>{t('ourspace.floor')}</p>
                                                        <p>{content.floor}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t('ourspace.electricity')}</p>
                                                        <p>{content.electricity} watt</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='section-heading mt-3'>
                                            <h2>{content.title}</h2>
                                            {
                                                (localStorage.i18nextLng === 'en') ? 
                                                <p className='mt-2'>{content.description_category_en_name}</p>
                                                :
                                                <p className='mt-2'>{content.description_category_id_name}</p>
                                            }
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center mt-lg-4 mt-md-3'>
                                            {/* <Button className='btn-consult me-2' onClick={handleShow}>{t('moreinfo')}</Button> */}
                                            {/* <Button className='btn-consult me-2' onClick={() => handleShow(content.id)}>{t('moreinfo')}</Button> */}
                                            <ReactWhatsapp number="+6281385602131" message={content.title} className="btn btn-wa ms-2">
                                                Whatsapp
                                            </ReactWhatsapp>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                	{/* <Container>
                        <Row className='align-items-center justify-content-between'>
                            <Col lg='5'> */}
                                <div className='list-space'>
                                    <OwlCarousel className="owl-theme" {...options}>
                                        {
                                            detail.images && detail.images.map((img, i) => {
                                                return(
                                                    <div className="item" key={i}>
                                                        <img src={img} alt='Detail Our Space' className='img-fluid' width='550' height='550' />
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                </div>
                            {/* </Col>
                        </Row>
                    </Container> */}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default List;