import React, { Fragment, useEffect } from 'react'
import { Container, Navbar, Nav, NavDropdown, Button, Dropdown } from 'react-bootstrap'
import $ from 'jquery'

import { useTranslation } from 'react-i18next'

function Header() {
    const { t, i18n } = useTranslation();
    const pathUrl = window.location.pathname;
    const pathHash = window.location.hash;
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const isSticky = (e) => {
		const header = document.querySelector("header");
		const scrollTop = window.scrollY;
		scrollTop >= 1
			? header.classList.add("sticky")
			: header.classList.remove("sticky");
	};

	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
    });

    const toggleClass = () => {
        const icon_bar = document.querySelector('#icon_bars');
        icon_bar.classList.toggle('fa-times');
        const menu = document.querySelector("div#basic-navbar-nav");
        menu.classList.toggle("show");
    }

    $(window).ready(function() {
        const heightHeader = $('#header').height();
        $('.wrapper').css({'padding-top': heightHeader+'px'})
        $('.dropdown-menu').css({"top": heightHeader+"px"})
        $('.nav-bg').css({"height": heightHeader+"px"})
        $('div#basic-navbar-nav').css({"top": heightHeader+"px"})
    });
    return (
        <Fragment>
            <header id='header'>
                <Navbar expand="lg" id="navbar_menu">
                    <Container>
                        <Navbar.Brand className="navbar-logo" href="/">
                            <img src="/logo.png" className="img-fluid" alt="logo grand slipi tower" width='100' height='47'/>
                        </Navbar.Brand>
                        <Navbar.Collapse className="align-items-center justify-content-end mx-auto d-none d-xl-flex d-lg-flex d-md-none">
                            <Nav as="ul" id="navigation" className="align-items-center justify-content-center mobile">
                                <li className="nav-item dropdown nav-bg">
                                    <a id="basic-nav-dropdown" aria-expanded="false" role="button" className={pathUrl === '/about-us' ? 'dropdown-toggle nav-link active' : 'dropdown-toggle nav-link'} tabIndex="0" href="#" >{t('menu.aboutus')}</a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Nav.Link href="/about-us#grand_slipi_tower" className={pathHash === '#grand_slipi_tower' ? 'dropdown-item active' : 'dropdown-item'}>Grand Slipi Tower</Nav.Link>
                                        <Nav.Link href="/about-us#pp_properti" className={pathHash === '#pp_properti' ? 'dropdown-item active' : 'dropdown-item'}>PT PP Properti Tbk</Nav.Link>
                                        <Nav.Link href="/about-us#our_team" className={pathHash === '#our_team' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.about.a1')}}/></Nav.Link>
                                        <Nav.Link href="/about-us#our_tenant" className={pathHash === '#our_tenant' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.about.a2')}}/></Nav.Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown nav-bg">
                                    <a id="basic-nav-dropdown" aria-expanded="false" role="button" className={pathUrl === '/floor-plan' ? 'dropdown-toggle nav-link active' : 'dropdown-toggle nav-link'} tabIndex="0" href="#" >{t('menu.floorplan')}</a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Nav.Link href="/floor-plan#lantai_6_10_soho" className={pathHash === '#lantai_6_10_soho' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a1')}}/></Nav.Link>
                                        <Nav.Link href="/floor-plan#lantai5_11_16" className={pathHash === '#lantai5_11_16' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a2')}}/></Nav.Link>
                                        <Nav.Link href="/floor-plan#lantai_18_penthouse" className={pathHash === '#lantai_18_penthouse' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a3')}}/></Nav.Link>
                                        <Nav.Link href="/floor-plan#area_parkir" className={pathHash === '#area_parkir' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a4')}}/></Nav.Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown nav-bg">
                                    <a id="basic-nav-dropdown" aria-expanded="false" role="button" className={pathUrl === '/benefit' ? 'dropdown-toggle nav-link active' : 'dropdown-toggle nav-link'} tabIndex="0" href="#" >{t('menu.benefit')}</a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Nav.Link href="/benefit#whats_special" className={pathHash === '#whats_special' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.benefit.a1')}}/></Nav.Link>
                                        <Nav.Link href="/benefit#building_facility" className={pathHash === '#building_facility' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.benefit.a2')}}/></Nav.Link>
                                        <Nav.Link href="/benefit#prime_locations" className={pathHash === '#prime_locations' ? 'dropdown-item active' : 'dropdown-item'}><div dangerouslySetInnerHTML={{ __html: t('second_menu.benefit.a3')}}/></Nav.Link>
                                    </div>
                                </li>
                                <Nav.Item as="li" className='nav-bg'>
                                    <Nav.Link className={pathUrl === '/our-space' ? 'nav-link active' : 'nav-link'} href="/our-space">{t('menu.ourspace')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className='nav-bg'>
                                    <Nav.Link className={pathUrl === '/news' ? 'nav-link active' : 'nav-link'} href="/news">{t('menu.news')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className='nav-bg'>
                                    <Nav.Link className={pathUrl === '/contact-us' ? 'nav-link active' : 'nav-link'} href="/contact-us">{t('menu.contact')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className="nav-bg phone h-100 d-flex align-items-center justify-content-center py-2 mx-2">
                                    <a href="tel:0215301111" target='_blank' rel='noreferrer' className='call'><i className="fa-solid fa-phone me-1"></i> 021-5301111</a>
                                </Nav.Item>
                                <Dropdown>
                                    <Dropdown.Toggle variant='default' id='dropdown-basic'>
                                        <img src={'/image/icon/'+localStorage.i18nextLng+'.png'} alt="lang" width='30' height='30' />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                                <Button variant='default' className={(localStorage.i18nextLng === 'id' ? 'active' : '') || (localStorage.i18nextLng === 'id-ID' ? 'active' : '')} onClick={() => changeLanguage('id')}><img src={'/image/icon/id.png'} alt="lang" width='40' height='40' /></Button>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Button variant='default' className={(localStorage.i18nextLng === 'en' ? 'active' : '') || (localStorage.i18nextLng === 'en-GB' ? 'active' : '')} onClick={() => changeLanguage('en')}><img src={'/image/icon/en.png'} alt="lang" width='40' height='40' /></Button>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="mobile_menu d-block d-lg-none" id="basic-navbar-nav" >
                            <Nav as="ul" id="navigation" className="">
                                <NavDropdown title={t('menu.aboutus')} id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/about-us#grand_slipi_tower">GRAND SLIPI TOWER</NavDropdown.Item>
                                    <NavDropdown.Item href="/about-us#pp_properti">PT PP PROPERTI TBK</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title={t('menu.floorplan')} id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/floor-plan#lantai_6_10_soho" ><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a1')}}/></NavDropdown.Item>
                                    <NavDropdown.Item href="/floor-plan#lantai5_11_16" ><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a2')}}/></NavDropdown.Item>
                                    <NavDropdown.Item href="/floor-plan#lantai_18_penthouse" ><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a3')}}/></NavDropdown.Item>
                                    <NavDropdown.Item href="/floor-plan#area_parkir" ><div dangerouslySetInnerHTML={{ __html: t('second_menu.floorplan.a4')}}/></NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Item as="li">
                                    <Nav.Link className="nav-link" href="/our-space">{t('menu.ourspace')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link className="nav-link" href="/benefit">{t('menu.benefit')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link className="nav-link" href="/news">{t('menu.news')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link className="nav-link" href="/contact-us">{t('menu.contact')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className="phone h-100 d-flex align-items-center justify-content-center py-2 m-2">
                                    <a href="tel:0215301111" target='_blank' rel='noreferrer' className='call'><i className="fa-solid fa-phone me-1"></i> 021-5301111</a>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='d-block d-xl-none d-lg-none d-md-block ms-auto'>
                            <Dropdown>
                                <Dropdown.Toggle variant='default' id='dropdown-basic'>
                                    <img src={'/image/icon/'+localStorage.i18nextLng+'.png'} alt="lang" width='30' height='30' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                            <Button variant='default' className={(localStorage.i18nextLng === 'id' ? 'active' : '') || (localStorage.i18nextLng === 'id-ID' ? 'active' : '')} onClick={() => changeLanguage('id')}><img src={'/image/icon/id.png'} alt="lang" width='40' height='40' /></Button>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Button variant='default' className={(localStorage.i18nextLng === 'en' ? 'active' : '') || (localStorage.i18nextLng === 'en-GB' ? 'active' : '')} onClick={() => changeLanguage('en')}><img src={'/image/icon/en.png'} alt="lang" width='40' height='40' /></Button>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Navbar.Toggle onClick={toggleClass}><i className="fas fa-bars" id="icon_bars"></i></Navbar.Toggle>
                    </Container>
                </Navbar>
            </header>
        </Fragment>
    );
}

export default Header;