import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import $ from 'jquery'

function About() {
    const { t } = useTranslation();

    const [tenants, listTenants] = useState([]);
    const [loading, setLoading] = useState();
    useEffect(() => {
        setLoading(true);
        const url = process.env.REACT_APP_API + `ourTenant`
        axios.get(url)
        .then(res => {
            listTenants(res.data)
            setLoading(false);
        })
        .catch(error => console.log(error))
    },[]);

    $(window).ready(function() {
        const heightHeader = $('#header').height();
        $('.wrapper').css({'padding-top': heightHeader+'px'})
        // $('.wording').css({
        //     "padding-top": heightHeader+'px', 
        //     "padding-bottom": heightHeader+'px'
        // });
    });
    return (
        <Fragment>
            <Container>
                <div className="about">
                    <section id='grand_slipi_tower' className='wording pb-0'>
                        <div className='section-heading text-center my-3 my-lg-5 my-md-4'>
                            <h2>{t('about.a1')}</h2>
                        </div>
                        <div className='section-body'>
                            <div dangerouslySetInnerHTML={{ __html: t('about.b1') }}/>
                        </div>
                    </section>
                    <section id='pp_properti' className='wording pb-0'>
                        <div className='section-heading text-center my-3 my-lg-5 my-md-4'>
                            <h2>{t('about.a2')}</h2>
                        </div>
                        <div className='section-body'>
                            <div dangerouslySetInnerHTML={{ __html: t('about.b2') }}/>
                        </div>
                    </section>
                    <section id='our_team' className='wording pb-0'>
                        <Container>
                            <div className='section-heading my-3 my-lg-5 my-md-4 text-center'>
                                <h2>{t('about.a3')}</h2>
                            </div>
                            <div className='img-team'>
                                <img src='/image/home/fasilitas/facility.jpg' alt='image our team' className='img-fluid' />
                            </div>
                            <div className='img-team mt-4'>
                                <img src='/image/about/strukur_organisasi.png' alt='image our team' className='img-fluid' />
                            </div>
                        </Container>
                    </section>
                    <section id='our_tenant' className='wording'>
                        <Container>
                            <div className='section-heading my-3 my-lg-5 my-md-4 text-center'>
                                <h2>{t('about.a4')}</h2>
                            </div>
                            <div className='section-body'>    
                                <Row className='justify-content-center align-items-center'>
                                    {
                                        loading ? 
                                        [0,1,2,3,4,5].map((i) => {
                                            return(
                                                <Col lg="2" md='3' xs='6' className='p-lg-2' key={i}>
                                                    <div className='item-tenant'>
                                                        <Skeleton width={170} height={120}/>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                        :
                                        tenants.map((logo, i) => {
                                            return(
                                                <Col lg="2" md='3' xs='6' className='p-lg-2' key={i}>
                                                    <div className='item-tenant'>
                                                        <img src={logo.link_image} alt={'tenant '+logo.name} className='img-fluid' width='170' height='120' />
                                                    </div>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </div>
                        </Container>
                    </section>
                </div>
            </Container>
        </Fragment>
    );
}

export default About;