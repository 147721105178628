import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Sosmed from '../partials/Sosmed'

// content
import Floor from '../content/floorplan/Floor'
import Detail from '../content/floorplan/Detail'

function FloorPlan() {
    return (
        <Fragment>
            <Helmet>
                <link rel="canonical" href="https://grandslipi.com/floor-plan" />
                <link rel='shortlink' href="https://grandslipi.com/floor-plan" />
                {/* Primary Meta Tags */}
                <title>Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat</title>
                <meta name="title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta name="description" content="Grand Slipi Tower berkolaborasi di salah satu kawasan bisnis tersibuk di Jakarta"/>
                <meta name="keywords" content="Lokasi strategis, sewa kantor, beli kantor di jakarta, pp propoerti, grand slipi tower, sewa kantor di jakarta barat"/>

                <meta itemprop="title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta itemprop="description" content="Grand Slipi Tower berkolaborasi di salah satu kawasan bisnis tersibuk di Jakarta"/>
                <meta itemprop="image" content="/fav-icon-gst.png"/>

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://grandslipi.com/floor-plan" />
                <meta property="og:title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta property="og:description" content="Grand Slipi Tower berkolaborasi di salah satu kawasan bisnis tersibuk di Jakarta"/>
                <meta property="og:image" content="/fav-icon-gst.png"/>

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://grandslipi.com/floor-plan" />
                <meta property="twitter:title" content='Grand Slipi Tower - Gedung perkantoran stategis di jakarta barat'/>
                <meta property="twitter:description" content="Grand Slipi Tower berkolaborasi di salah satu kawasan bisnis tersibuk di Jakarta"/>
                <meta property="twitter:image" content="/fav-icon-gst.png"/>
                
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="googlebot-news" content="index, follow"/>
            </Helmet>
            <Sosmed/>
            <h1 className="firstHeading MSI_show_element">Floor Plan - Grand Slipi Tower Apartment in Slipi</h1>
            <Floor/>
            <Detail/>
        </Fragment>
    );
}

export default FloorPlan;