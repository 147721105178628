import React, { Component, Fragment } from 'react'
import ReactWhatsapp from 'react-whatsapp'

class WhatsApp extends Component {
    render() {
        return (
            <Fragment>
                <ReactWhatsapp number="+6281385602131" className="wa">
                    <img className="img-fluid" src="/image/icon/wa.png" alt="Logo WhatsApp" width="40" height="40"/>
                </ReactWhatsapp>
            </Fragment>
        );
    }
}

export default WhatsApp;