import React, { Fragment } from 'react';

function Service() {
    return (
        <Fragment>
            <a href="tel:0215301111" target='_blank' rel='noreferrer'><img src='/image/icon/cs.png' alt='Logo Service' width="40" height="40"/></a>
        </Fragment>
    );
}

export default Service;