import React, { Fragment } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import $ from "jquery"

function Detail() {
    const { t } = useTranslation();
    $(window).ready(function() {
        const heightHeader = $('#header').height();
        $('.wrapper').css({'padding-top': heightHeader+'px'})
        $('.floor_plan').css({
            "padding-top": heightHeader +'px'
        });
    });
    return (
        <Fragment>
            <section className='floorplan pb-0'>
                <div className='section-heading text-center mb-lg-4'>
                    <h2><div dangerouslySetInnerHTML={{ __html: t('floorplan.a4')}}/></h2>
                </div>
                <Container>
                    <Row>
                        <div dangerouslySetInnerHTML={{ __html: t('floorplan.b4')}}/>
                    </Row>
                </Container>
            </section>
            <Container fluid>
                <section className='floorplan floor_plan' id='area_parkir'>
                    <div className='section-heading text-center my-3 my-lg-5 my-md-4'>
                        <h2><div dangerouslySetInnerHTML={{ __html: t('floorplan.a5')}}/></h2>
                    </div>
                    <Row className='row-eq-height'>
                        <Col lg='6' md='6' style={{backgroundColor: '#F9F9F9'}} className='p-4'>
                            <div dangerouslySetInnerHTML={{ __html: t('floorplan.b5')}}/>
                        </Col>
                        <Col lg='6' md='6' className='p-0'>
                            <div className='d-none d-lg-block d-md-block' style={{backgroundImage: 'url(/image/floorplan/parking-area.jpg)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%', height: '352px'}}/>
                            <div className='d-block d-lg-none d-md-none'>
                                <picture>
                                    <source srcSet="/image/floorplan/parking-area.webp" type="image/webp"/>
                                    <source srcSet="/image/floorplan/parking-area.jpg" type="image/jpeg"/>
                                    <LazyLoadImage alt='Parking Area' srcSet="/image/floorplan/parking-area.jpg" width='400' height='370' className='img-fluid' />
                                </picture>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
            <section className='floorplan'>
                <Container>
                    <div className='section-body'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header as='p'>{t('floorplan.a6')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b6')}}/>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header as='p'>{t('floorplan.a7')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b7')}}/>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header as='p'>{t('floorplan.a8')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b8')}}/>                                    
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header as='p'>{t('floorplan.a9')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b9')}}/>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header as='p'>{t('floorplan.a10')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b10')}}/>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header as='p'>{t('floorplan.a11')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b11')}}/>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header as='p'>{t('floorplan.a12')}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: t('floorplan.b12')}}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default Detail;