import React, { Fragment } from 'react'

function Hero() {
    return (
        <Fragment>
            <section className='hero' style={{backgroundImage: 'url(/image/about/hero.jpg)'}}>

            </section>
        </Fragment>
    );
}

export default Hero;