import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

function OtherContent() {
    const [otherarticle, listOtherArticle] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const url = process.env.REACT_APP_API + `article`
        axios.get(url)
        .then(res => {
            listOtherArticle(res.data)
            setLoading(false);
        })
        .catch(error => console.log(error))
    },[]);
    return (
        <Fragment>
            <div className='other_article mt-5'>
                <Row>

                    {
                        loading ? 
                        [0,1,2,].map((i) => {
                            return(
                                <Col lg='4' md='6' xs='12' className='pb-3' key={i}>
                                    <Card>
                                        <Skeleton height={180}/>
                                        <Card.Body>
                                            <Skeleton count={2}/>
                                            <Card.Text>
                                                <Skeleton count={3}/>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })
                        :
                        otherarticle.slice(0, 3).map((content, i) => {
                            return(
                                <Col lg='4' md='6' xs='12' className='pb-3' key={i}>
                                    <Card>
                                        <Card.Img variant="top" src={content.thumbImage.medium} width="350" height="200" alt={content.title} />
                                        <Card.Body>
                                            <a href={"/news/" + content.slug }>
                                                <Card.Title as='h2'>{content.title}</Card.Title>
                                            </a>
                                            <Card.Text>
                                                {content.excerpt}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </Fragment>
    );
}

export default OtherContent;