import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import ScrollTop from './ScrollTop'

// common
import Header from './components/common/Header'
import Footer from './components/common/Footer'

// pages
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import FloorPlan from './components/pages/FloorPlan'
import OurSpace from './components/pages/OurSpace'
import Benefit from './components/pages/Benefit'
import News from './components/pages/News'
import DetailNews from './components/pages/DetailNews'
import ContactUs from './components/pages/ContactUs'

function App() {
  return (
    <Fragment>
      <ScrollTop>
        <Header/>
        <div className='wrapper'>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path="/floor-plan" component={FloorPlan} />
            <Route exact path="/our-space" component={OurSpace} />
            <Route exact path="/benefit" component={Benefit} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/:slug" component={DetailNews} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route path="*" render={() => <div>404</div>} />
          </Switch>
        </div>
        <Footer/>
      </ScrollTop>
    </Fragment>
  );
}

export default App;