import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'

function Content() {
    const { t } = useTranslation();
    const [article, listArticle] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect( () => {
        setLoading(true);
        const url = process.env.REACT_APP_API + `article`
        axios.get(url)
        .then(res => {
            listArticle(res.data)
            setLoading(false);
        })
        .catch(error => console.log(error))
    },[]);
    if (article.length === 0) {
        return(
            <section className='news'>
                <Container>
                    <div className='section-heading mb-3 mb-lg-5 mb-md-4 text-center'>
                        <h2>{t('notfounda1')}</h2>
                    </div>
                </Container>
            </section>
        );
    }
    return (
        <Fragment>
            <section className='news'>
                <Container>
                    <div className='section-heading mb-3 mb-lg-5 mb-md-4 text-center'>
                        <h2>{t('news.title')}</h2>
                    </div>
                    <Row>
                        {
                            loading ? 
                            [0,1,2,3,4,5].map((i) => {
                                return(
                                    <Col lg='4' md='6' xs='12' className='pb-3' key={i}>
                                        <Card>
                                            <Skeleton height={180}/>
                                            <Card.Body>
                                                <Skeleton count={2}/>
                                                <Card.Text>
                                                    <Skeleton count={3}/>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })
                            :
                            article.map((content, i) => {
                                return(
                                    <Col lg='4' md='6' xs='12' className='pb-3' key={i}>
                                        <Card>
                                            <Card.Img variant="top" src={content.thumbImage.medium} alt={content.title} width="350" height="200" />
                                            <Card.Body>
                                                <a href={"/news/" + content.slug }>
                                                    <Card.Title as='h3'>{content.title}</Card.Title>
                                                </a>
                                                <Card.Text>
                                                    {content.excerpt}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Content;