import React, { Component, Fragment } from 'react'
import OwlCarousel from 'react-owl-carousel'
import axios from 'axios'
import { Container, Row, Col } from 'react-bootstrap'

class Hero extends Component {
    state = {
        listBanner: [],
        textBanner: []
    }

    componentDidMount(){
        this.loadListBanner()
        this.loadTextBanner()
    }

    loadListBanner = () => {
        const url = process.env.REACT_APP_API + `banner`;
        axios.get(url)
        .then(res => {
            const listBanner = res.data;
            this.setState({listBanner});
        })
    }

    loadTextBanner = () => {
        const url = process.env.REACT_APP_API + `textBanner`;
        axios.get(url)
        .then(res => {
            const textBanner = res.data;
            this.setState({textBanner});
        })
    }
    render() {
        const options = {
            loop: true,
            dots: true,
            nav: false,
            autoplay: true,
            smartSpeed: 1250,
            items: 1
        }
        const txtBanner = this.state.textBanner;
        return (
            <Fragment>
                <section className="hero">
                    {
                        this.state.listBanner.length && (
                            <OwlCarousel className="owl-theme" {...options}>
                                {
                                    this.state.listBanner.map((e, i) => {
                                        if(e.page === 'home'){
                                            return(
                                                <div className="item" style={{backgroundImage: 'url('+e.link_image+')'}} key={i}>
                                                    {/* <img src={e.link_image} alt={e.name} className='img-fluid'/> */}
                                                    <div className='overlay'></div>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </OwlCarousel>
                        )
                    }
                    <div className="banner-content">
                        <div className="hero-content">
                            <Container>
                                <Row className="align-items-center justify-content-center d-flex">
                                    <Col md={8} className="text-center">
                                        <h2>
                                            {txtBanner.textbanner}
                                        </h2>
                                        <p>{txtBanner.body_textbanner}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default Hero;