import React, { Fragment } from 'react';

function Facebook() {
    return (
        <Fragment>
            <a href="https://www.facebook.com/grandslipitower" target='_blank' rel='noreferrer'>
                <img src='/image/icon/fb.png' alt="Logo Facebook"  width="40" height="40"/>
            </a>
        </Fragment>
    );
}

export default Facebook;