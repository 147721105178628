import React, {Fragment} from 'react'
import WA from './WhatsApp'
import FB from './Facebook'
import IG from './Instagram'
import CS from './Service'

function Sosmed() {
    return (
        <Fragment>
            <div className='fly'>
                <ul className='list-unstyled'>
                    <li><WA/></li>
                    <li><IG/></li>
                    <li><FB/></li>
                    <li><CS/></li>
                </ul>
            </div>
        </Fragment>
    );
}

export default Sosmed;