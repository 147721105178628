import React, { Fragment, useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import axios from 'axios'
import { useTranslation } from 'react-i18next'


export default function Testimoni() {
    const { t } = useTranslation();
	const [testimoni, listTestimoni] = useState([]);
    useEffect( () => {
        const url = process.env.REACT_APP_API + `testimoni`
        axios.get(url)
        .then(res => {
            listTestimoni(res.data)
        })
        .catch(error => console.log(error))
    },[]);
	const options = {
		margin: 20,
		loop: true,
		dots: true,
		nav: false,
		autoplay: true,
		smartSpeed: 1300,
		autoHeightClass: 'owl-height',
		responsive: {
			0:{
			  	items: 1
			},
			480:{
			  	items: 3
			},
			769:{
			  	items: 3
			}
		}
	}
	if (testimoni.length === 0) {
		return (
			<Fragment></Fragment>
		)
	}
	return (
		<Fragment>
			<section className='area-testimoni'>
				<Container>
					<div className='section-heading mb-3 mb-lg-5 mb-md-4 text-center'>
						<h2>{t('testimoni.title')}</h2>
					</div>
					<div className='section-body'>
						{
							testimoni.length && (
								<OwlCarousel className="owl-theme" {...options}>
									{
										testimoni.map((data, i) => {
											return(
												<Card id="cardtestimonmi" key={i}>
													<Card.Header>
														<p>{data.customerName}</p>
														<span>{data.customerAs}</span>
													</Card.Header>
													<Card.Body>
														<Card.Text>
															{data.message}
														</Card.Text>
													</Card.Body>
												</Card>
											)
										})
									}
								</OwlCarousel>
							)
						}
					</div>
				</Container>
			</section>
		</Fragment>
	)
}
